<template>
  <div>
    <TitleWrapper :filter-option="true" title="Student Result" :display-breadcrumb="true" />
    <div class="main-content mt-5">
      <div class="flex justify-between"></div>
      <div v-if="isLoading" class="mt-28">
        <Loader :content="true" />
      </div>
      <div v-else-if="!isLoading && isEmpty(resultsList)">
        <NoRecordFound />
      </div>
      <div v-else class="w-full mt-5">
        <DetailCard :details="studentResultDetails" custom-classes="flex-col" />
        <Heading heading="Student Result" heading-size="pb-0 text-xl" />
        <TableWrapper>
          <THead>
            <TRHead>
              <TH v-for="(head, index) in tableHeads" :key="index">
                {{ head }}
              </TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(result, index) in resultsList" :key="index">
              <TD>
                {{ result.subject.title }}
              </TD>
              <TD>
                {{ result.total_marks }}
              </TD>
              <TD>
                {{ result.obtained_marks }}
              </TD>
              <TD>
                <UiStatus :title="result.status" :success="result.status === PASS" />
              </TD>
              <TD>
                {{ result.grade_title }}
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
      </div>
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import GeneralUtil from '@src/mixins/general-mixins.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import isEmpty from 'lodash/isEmpty'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import DetailCard from '@/src/components/UiElements/DetailCard.vue'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import Heading from '@/src/components/Heading.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  components: {
    TitleWrapper,
    Loader,
    NoRecordFound,
    UiStatus,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
    DetailCard,
    Heading,
  },
  mixins: [GeneralUtil, scrollMixin],
  data() {
    return {
      PASS: 'pass',
      resultsList: [],
      studentResultDetails: {},
      isLoading: false,
      tableHeads: ['Subject', 'Total Marks', 'Marks Obtained', 'Status', 'Grade'],
      isEmpty,
    }
  },
  created() {
    this.getResult()
    this.setRightBar()
  },
  methods: {
    ...mapActions('layout', ['setRightbarContent', 'singleStudentExamResult']),
    ...mapActions('exams', ['singleStudentExamResult']),

    setRightBar(student_meta = { first_name: '', last_name: '', image: '' }) {
      const rightBarContent = {
        header: {
          title: 'RESULT',
        },
        profileInfo: [
          {
            imageUrl: student_meta.image,
            hideLogininfo: true,
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    async getResult() {
      this.isLoading = true
      const id = this.$route.params.resultId
      const [res, err] = await this.singleStudentExamResult(id)
      if (res) {
        this.resultsList = res.data.student_subject_wise_results
        fullName(res.data.student)
        this.studentResultDetails = {
          studentName: res.data.student.full_name,
          studentEmail: res.data.student.email,
        }
        let rightbarData = this.getRightBarData(res.data)
        this.setRightBar(rightbarData)
        this.isLoading = false
      }
    },
    getRightBarData(result) {
      return {
        first_name: result?.student?.first_name || '',
        last_name: result?.student?.last_name || '',
        image: result?.student?.img || null,
      }
    },
  },
}
</script>
